import React from "react";
import "../styles/App.scss";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function Footer() {
	return (
		<footer>
			<Navbar className="container navbarBottom" expand="lg" fixed="bottom">
				<Nav className="navbarBottomList">
					{/* <Nav.Link className="navbarBottomLinks" as={Link} to="/privacy">
						プライバシー
					</Nav.Link>
					<Nav.Link className="navbarBottomLinks" as={Link} to="/about">
						Senreiについて
					</Nav.Link> */}
					<Nav.Link
						className="navbarBottomLinks"
						href="https://tomasjsierra.me/"
						target="_blank"
					>
						作成中 by Tomas
					</Nav.Link>
				</Nav>
			</Navbar>
		</footer>
	);
}

export default Footer;
