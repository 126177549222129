import React from "react";
import { Link } from "react-router-dom";
import "../styles/App.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Image from "react-bootstrap/Image";
import MacbookPro from "../assets/macbookwithapp.png";
import Pixel from "../assets/pixelwithapp.png";
import { Animated } from "react-animated-css";

function Privacy() {
	return (
		<>
			<Header />
			<main className="container homeContent singlePage">
				<section aria-labelledby="hero-text" className="heroText">
					<Animated
						animationIn="fadeInUp"
						animationInDelay="100"
						animationInDuration="1000"
						isVisible={true}
					>
						<h2 className="subtitle">プライバシー</h2>
						<p className="description"></p>
						<p className="description">
							平成18年12月9日
							<p className="description">アスタミューゼ株式会社</p>
							<p className="description">
								アスタミューゼ株式会社(以下「弊社」とする。)は、
								個人情報の保護に関する法律（個人情報保護法）その他関連法令、
								関係官庁からのガイドラインなどを遵守し、
								お客様の個人情報を適正にお取扱いし、
								お客様のプライバシーを最大限に尊重致します。
								また、お客様の信頼に基づく責任を十分認識し、
								ご提供頂いたお客様の個人情報は適切かつ厳重に管理致します。
							</p>
							<p className="description">個人情報の収集・利用に関して</p>
							<p className="description">
								弊社では、より良いサービスの実現の為、
								適法で公正な手段によってお客様の個人情報をお伺いする場合がございます。
								なお、個人情報を収集・利用させて頂く際には、事前に収集・
								利用の目的を明確に開示いたします。また、
								事前に開示した目的以外での利用は一切いたしません。
							</p>
							<p className="description">プライバシーの尊重について</p>
							<p className="description">
								弊社では司法機関、
								行政機関等から法的な義務を伴う要請を受けた場合を除いて、
								お客様からご提供頂いた個人情報をご本人の許可を
								なくして第三者に開示することはいたしません。
							</p>
							<p className="description">個人情報の管理について</p>
							<p className="description">
								弊社では従業員一人一人に対して、
								お客様の個人情報を取り扱う際の教育を徹底しております。
								また、お客様からお預かりした個人情報が、
								紛失、破壊、社外への不正な流出、改ざんされないよう、
								最大限のセキュリティー対策・安全対策を実施しております。
							</p>
							<p className="description">
								個人情報保護法に基づく保有個人データの開示、訂正、利用停止など
							</p>
							<p className="description">
								個人情報保護法に基づく保有個人データに関する
								開示、訂正、利用停止などのご請求につきましては、
								ご請求者がご本人であることの確認をさせていただいたうえで手続きを行います。
								なお、上記開示などの手続きにつきましては所定の手数料をいただきます。
								手続きをご希望の方は、下記お問い合わせ窓口までお申し付けください。
							</p>
							<p className="description">
								プライバシーポリシーの見直し・改定について
							</p>
							<p className="description">
								弊社では、法令等の変更や社会情勢の変化などの必要に応じて、
								本「プライバシーポリシー」の内容を変更することができるものとします。
								変更後の内容は本サイトのトップページからリンクされた特定のウェブページにおいて掲載するものとし、
								掲載後1カ月を経過した時点で変更後の内容が有効になるものとします。
							</p>
							<p className="description">
								個人情報に関するお問い合わせについて
							</p>
							<p className="description">
								弊社における個人情報の取り扱いに関するご意見、
								ご質問はお問い合わせ窓口へお申し付けください。
								誠意を持って迅速に対応させて頂きます。
							</p>
						</p>
						<div className="singlePageGoBack">
							<span className="singlePageGoBackSpan">
								<Link className="singlePageGoBackLink" to="/">
									← 戻る
								</Link>
							</span>
						</div>
					</Animated>
				</section>
				<section aria-labelledby="hero-devices" className="heroDevices">
					<Image
						src={MacbookPro}
						className="macbookPro"
						alt="Senrei.jp desktop"
					/>
					<Image src={Pixel} className="iPhone" alt="Senrei.jp mobile" />
				</section>
			</main>
			<Footer />
		</>
	);
}

export default Privacy;
