import React from "react";
import "../styles/App.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Image from "react-bootstrap/Image";
import MacbookPro from "../assets/macbookwithapp.png";
import Pixel from "../assets/pixelwithapp.png";
import { Animated } from "react-animated-css";

function Home() {
	return (
		<>
			<Animated
				animationIn="fadeIn"
				animationInDelay="100"
				animationInDuration="1000"
				isVisible={true}
			>
				<Header />
			</Animated>
			<main className="container homeContent">
				<section aria-labelledby="hero-text" className="heroText">
					<Animated
						animationIn="fadeIn"
						animationInDelay="500"
						animationInDuration="1000"
						isVisible={true}
					>
						<header>
							<h1 className="title">
								判例の検索は、
								<div className="alphTitle">Senrei.jp</div>
							</h1>
							<h2 className="subtitle">全国の判例検索アプリ</h2>
						</header>
						<p className="description">このアプリケーションは現在開発中</p>
						{/* <p className="leaveMailDescription">
							betaバージョンの使用をご希望の方はこちらへ
						</p>
						<InputGroup className="leaveMailGroup">
							<FormControl
								className="leaveMailInput"
								placeholder="メールアドレス"
								aria-label="メールアドレス"
							/>
							<Button className="shareMailButton" id="">
								<div className="shareMailButtonIcon">
									<Tooltip />
								</div>
							</Button>
						</InputGroup>
						<p className="dontShare">
							<Link className="dontShareLink" to="/privacy">
								プライバシーポリシー
							</Link>
						</p> */}
					</Animated>
				</section>
				<section aria-labelledby="hero-devices" className="heroDevices">
					<Animated
						animationIn="fadeIn"
						animationInDelay="750"
						animationInDuration="2000"
						isVisible={true}
					>
						<Image
							src={MacbookPro}
							className="macbookPro"
							alt="Senrei.jp desktop"
						/>
						<Image src={Pixel} className="iPhone" alt="Senrei.jp mobile" />
					</Animated>
				</section>
			</main>
			<Animated
				animationIn="fadeIn"
				animationInDelay="1000"
				animationInDuration="3000"
				isVisible={true}
			>
				<Footer />
			</Animated>
		</>
	);
}

export default Home;
