import React from "react";
import { Link } from "react-router-dom";
import "../styles/App.scss";
import Navbar from "react-bootstrap/Navbar";
import { Animated } from "react-animated-css";

function Header() {
	return (
		<header>
			<Navbar
				className="container NavbarTop"
				expand="lg"
				fixed="top"
				as={Link}
				to="/"
			>
				<div>
					<h1 className="logoText">
						<span className="senrei">Senrei</span>
						<span className="dot">.</span>
						<span className="jp">jp</span>
					</h1>
					<Animated
						animationIn="fadeIn"
						animationInDelay="2000"
						animationInDuration="1000"
					>
						<h2 className="beta">beta</h2>
					</Animated>
				</div>
			</Navbar>
		</header>
	);
}

export default Header;
