import React from "react";
import { Link } from "react-router-dom";
import "../styles/App.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Image from "react-bootstrap/Image";
import MacbookPro from "../assets/macbookwithapp.png";
import Pixel from "../assets/pixelwithapp.png";
import { Animated } from "react-animated-css";

function About() {
	return (
		<>
			<Header />
			<main className="container homeContent singlePage">
				<section aria-labelledby="hero-text" className="heroText">
					<Animated
						animationIn="fadeInUp"
						animationInDelay="100"
						animationInDuration="1000"
						isVisible={true}
					>
						<h2 className="subtitle">Senrei.jpについて</h2>
						<p className="description">
							これはSenrei.jpの「について」ページです。
						</p>
						<p className="description">
							これはSenrei.jpの「について」ページです。これはSenrei.jpの「について」ページです。これはSenrei.jpの「について」ページです。これはSenrei.jpの「について」ページです。
						</p>
						<div className="singlePageGoBack">
							<span className="singlePageGoBackSpan">
								<Link className="singlePageGoBackLink" to="/">
									← 戻る
								</Link>
							</span>
						</div>
					</Animated>
				</section>
				<section aria-labelledby="hero-devices" className="heroDevices">
					<Image
						src={MacbookPro}
						className="macbookPro"
						alt="Senrei.jp desktop"
					/>
					<Image src={Pixel} className="iPhone" alt="Senrei.jp mobile" />
				</section>
			</main>
			<Footer />
		</>
	);
}

export default About;
